import React from 'react';
import classnames from 'classnames';

import logoImage from './logo.svg';

import style from './Logo.sass';

export interface Props {
  className?: string;
}

const Logo: React.FC<Props> = (props) => (
  <img {...props} src={logoImage} alt="Botond Veress" className={classnames(style.root, props.className)} />
);

export default Logo;
