import React from 'react';
import classnames from 'classnames';

import style from './Page.sass';

export interface Props {
  className?: string;
}

const Page: React.FC<Props> = (props) => <main {...props} className={classnames(style.root, props.className)} />;

export default Page;
