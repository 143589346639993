import React from 'react';
import classnames from 'classnames';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

export type Props = LinkProps & {
  exact?: boolean;
  activeClassName: string;
};

const ActiveLink: React.FC<Props> = ({ exact, activeClassName, children, ...rest }) => {
  const router = useRouter();

  const active = exact
    ? router.asPath === rest.href || router.asPath === rest.as
    : router.asPath.indexOf(rest.href.toString()) === 0 ||
      (!!rest.as && router.asPath.indexOf(rest.as.toString()) === 0);

  // @ts-ignore
  const child: React.ReactElement = React.Children.only(children);

  return (
    <Link {...rest}>
      {React.cloneElement(child, {
        className: classnames(child.props.className, {
          [activeClassName]: active && !!activeClassName
        })
      })}
    </Link>
  );
};

export default ActiveLink;
