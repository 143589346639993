import React from 'react';
import classnames from 'classnames';
import ScrollLock from 'react-scrolllock';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { urls } from '@/constants';
import Container from '@/components/Container';
import Logo from '@/components/Logo';
import ActiveLink from '@/components/ActiveLink';
import Typography from '@/components/Typography';

import MenuButton from './MenuButton';

import style from './Header.sass';

export interface Props {}

const Header: React.FC<Props> = () => {
  const [isOpen, setOpen] = React.useState(false);

  const toggle = React.useCallback(() => setOpen(!isOpen), [isOpen]);

  const router = useRouter();

  React.useEffect(() => {
    // some delay so the page transition will start
    setTimeout(() => setOpen(false), 1000);
  }, [router.route]);

  return (
    <header className={style.header}>
      <div className={classnames(style.root, { [style.open]: isOpen })}>
        <Container full>
          <div className={style.content}>
            <Link href={urls.home}>
              <a className={style.logo}>
                <Logo />
              </a>
            </Link>

            <ScrollLock isActive={isOpen} />

            <nav id="navigation" className={style.navigation}>
              <div className={style.navigationContent}>
                <Container className={style.navigationContainer}>
                  <ul className={style.top}>
                    <li className={style.item}>
                      <ActiveLink href={urls.home} exact passHref activeClassName={style.active}>
                        <Typography is="a" color="primary" className={style.link}>
                          Home
                        </Typography>
                      </ActiveLink>
                    </li>

                    <li className={style.item}>
                      <ActiveLink href={urls.work} exact passHref activeClassName={style.active}>
                        <Typography is="a" color="primary" className={style.link}>
                          Work
                        </Typography>
                      </ActiveLink>
                    </li>

                    <li className={style.item}>
                      <ActiveLink href={urls.blog} passHref activeClassName={style.active}>
                        <Typography is="a" color="primary" className={style.link}>
                          Blog
                        </Typography>
                      </ActiveLink>
                    </li>

                    <li className={style.item}>
                      <ActiveLink href={urls.contact} exact passHref activeClassName={style.active}>
                        <Typography is="a" color="primary" className={style.link}>
                          Contact
                        </Typography>
                      </ActiveLink>
                    </li>
                  </ul>
                </Container>
              </div>
            </nav>
          </div>
        </Container>

        <MenuButton
          active={isOpen}
          className={style.toggle}
          contentClassName={style.toggleContent}
          aria-expanded={isOpen}
          aria-label="Open Navigation"
          aria-controls="navigation"
          onClick={toggle}
        />
      </div>
    </header>
  );
};

export default Header;
