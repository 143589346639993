import React from 'react';
import Link from 'next/link';

import { urls } from '@/constants';
import Container from '@/components/Container';
import Typography from '@/components/Typography';
import Spacer from '@/components/Spacer';

import style from './Footer.sass';

export interface Props {}

const year = new Date().getFullYear();

const Footer: React.FC<Props> = () => (
  <footer className={style.root}>
    <Container>
      <Spacer xs={4}>
        <div className={style.navigation}>
          <Spacer xs={6}>
            <nav>
              <Spacer xs={3} type="margin">
                <Typography is="h3" type="h3" color="ternary">
                  Content
                </Typography>
              </Spacer>

              <ul>
                <Spacer xs={1}>
                  <li>
                    <Link href={urls.home} passHref>
                      <Typography is="a" color="ternary">
                        Home
                      </Typography>
                    </Link>
                  </li>
                </Spacer>

                <Spacer xs={1}>
                  <li>
                    <Link href={urls.work} passHref>
                      <Typography is="a" color="ternary">
                        Work
                      </Typography>
                    </Link>
                  </li>
                </Spacer>

                <li>
                  <Link href={urls.blog} passHref>
                    <Typography is="a" color="ternary">
                      Blog
                    </Typography>
                  </Link>
                </li>
              </ul>
            </nav>
          </Spacer>

          <Spacer xs={6}>
            <nav>
              <Spacer xs={3} type="margin">
                <Typography is="h3" type="h3" color="ternary">
                  Reach out
                </Typography>
              </Spacer>

              <ul>
                <Spacer xs={1}>
                  <li>
                    <Link href={urls.contact} passHref>
                      <Typography is="a" color="ternary">
                        Contact
                      </Typography>
                    </Link>
                  </li>
                </Spacer>

                <Spacer xs={1}>
                  <li>
                    <Typography is="a" color="ternary" href={urls.github} target="_blank" rel="noopener noreferrer">
                      GitHub
                    </Typography>
                  </li>
                </Spacer>

                <li>
                  <Typography is="a" color="ternary" href={urls.linkedin} target="_blank" rel="noopener noreferrer">
                    LinkedIn
                  </Typography>
                </li>
              </ul>
            </nav>
          </Spacer>

          <Spacer xs={6}>
            <nav>
              <Spacer xs={3} type="margin">
                <Typography is="h3" type="h3" color="ternary">
                  Assets
                </Typography>
              </Spacer>

              <ul>
                <li>
                  <Typography is="a" color="ternary" href={urls.cv} target="_blank">
                    Resume
                  </Typography>
                </li>
              </ul>
            </nav>
          </Spacer>

          <Spacer xs={6}>
            <nav>
              <Spacer xs={3} type="margin">
                <Typography is="h3" type="h3" color="ternary">
                  Legal
                </Typography>
              </Spacer>

              <ul>
                <Spacer xs={1}>
                  <li>
                    <Link {...urls.legal('terms-and-conditions')} passHref>
                      <Typography is="a" color="ternary">
                        Terms & Conditions
                      </Typography>
                    </Link>
                  </li>
                </Spacer>

                <Spacer xs={1}>
                  <li>
                    <Link {...urls.legal('privacy-policy')} passHref>
                      <Typography is="a" color="ternary">
                        Privacy Policy
                      </Typography>
                    </Link>
                  </li>
                </Spacer>

                <li>
                  <Link {...urls.legal('cookie-policy')} passHref>
                    <Typography is="a" color="ternary">
                      Cookie Policy
                    </Typography>
                  </Link>
                </li>
              </ul>
            </nav>
          </Spacer>
        </div>
      </Spacer>

      <Typography is="p" color="secondary" block className={style.copyright}>
        &copy; {year} Botond Veress. All rights reserved.
      </Typography>
    </Container>
  </footer>
);

export default Footer;
