import React from 'react';
import Head from 'next/head';
import NextApp from 'next/app';

import 'reset-css';
import '@/style/base.global.sass';

import ErrorBoundary from '@/components/ErrorBoundary';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Page from '@/components/Page';

export interface Props {}

export default class App extends NextApp<Props> {
  render() {
    const { Component, pageProps, router } = this.props;

    // during SSR router.isSsr is undefined so we check if we are client or server-side
    const ssr = !!(router.isSsr ?? !process.browser);

    return (
      <ErrorBoundary>
        <React.Fragment>
          <Head>
            <base href="/" />

            <meta
              key="viewport"
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge, chrome=1" />

            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="theme-color" content="#fff" />
            <meta name="application-name" content="Botond Veress" />
            <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="167x167" href="/apple-touch-icon-167x167.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
            <link rel="apple-touch-icon" sizes="1024x1024" href="/apple-touch-icon-1024x1024.png" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            <meta name="apple-mobile-web-app-title" content="Botond Veress" />
            <link rel="yandex-tableau-widget" href="/yandex-browser-manifest.json" />

            <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link
              href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Muli:wght@400;700;900&display=block"
              rel="stylesheet"
            />
          </Head>

          <Header />

          <Page>
            <Component {...pageProps} ssr={ssr} />
          </Page>

          <Footer />
        </React.Fragment>
      </ErrorBoundary>
    );
  }
}

// export const reportWebVitals = (metric) => {
//   // These metrics can be sent to any analytics service
//   console.log(metric);
// };
