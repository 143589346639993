import React from 'react';
import classnames from 'classnames';

import style from './MenuButton.sass';

export interface Props extends React.ButtonHTMLAttributes<any> {
  active: boolean;
  className?: string;
  contentClassName?: string;
}

const MenuButton: React.FC<Props> = ({ active, className, contentClassName, ...rest }) => (
  <button {...rest} className={classnames(style.root, { [style.active]: active }, className)} type="button">
    <span className={classnames(style.content, contentClassName)} />
  </button>
);

export default MenuButton;
